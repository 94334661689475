import { render, staticRenderFns } from "./MovedArrow.vue?vue&type=template&id=f5484938&scoped=true&"
import script from "./MovedArrow.vue?vue&type=script&lang=js&"
export * from "./MovedArrow.vue?vue&type=script&lang=js&"
import style0 from "./MovedArrow.vue?vue&type=style&index=0&id=f5484938&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5484938",
  null
  
)

export default component.exports