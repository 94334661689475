<template>
  <div>
    <v-app-bar
      class="header"
      :class="{ 'header-mobile': $vuetify.breakpoint.smAndDown }"
      elevation="0"
    >
      <v-container>
        <v-toolbar-items class="w-100">
          <v-row class="justify-space-between align-center header-row">
            <v-col class="col-1 py-0 px-0 pt-2">
              <img
                @click="$router.push({ name: 'main' }).catch(() => {})"
                class="cursor-pointer"
                src="@/assets/images/logo.png"
                :width="!$vuetify.breakpoint.smAndDown ? '200px' : '150px'"
              />
            </v-col>
            <v-col
              class="col-8 col-lg-4 px-0 mt-2"
              v-if="!$vuetify.breakpoint.smAndDown"
            >
              <v-row
                class="justify-space-between text-uppercase text-color-white font-16"
              >
                <div
                  class="cursor-pointer position-relative"
                  @click="scrollToId('about_us')"
                >
                  <span class="link link--metis">Über uns</span>
                </div>
                <div
                  class="cursor-pointer position-relative"
                  @click="scrollToId('projects')"
                >
                  <span class="link link--metis">Projekte</span>
                </div>
                <div
                  class="cursor-pointer position-relative"
                  @click="scrollToId('production')"
                >
                  <span class="link link--metis">Produktion</span>
                </div>
                <div
                  class="cursor-pointer position-relative"
                  @click="scrollToId('contacts')"
                >
                  <span class="link link--metis">Kontakte</span>
                </div>
              </v-row>
            </v-col>
            <v-col class="col-1 mr-4" v-if="$vuetify.breakpoint.smAndDown">
              <v-btn @click="navigationDrawer = true" icon
                ><v-icon large color="#EFEFEF">mdi-menu</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-toolbar-items>
      </v-container>
    </v-app-bar>
    <v-expand-transition>
      <div v-if="navigationDrawer" class="navigation-drawer">
        <v-app-bar class="header-drawer" elevation="0">
          <v-container>
            <v-toolbar-items class="w-100">
              <v-row class="justify-space-between align-center header-row">
                <v-col class="col-1 py-0 px-0">
                  <img
                    @click="$router.push({ name: 'main' })"
                    class="cursor-pointer"
                    src="@/assets/images/logo.png"
                    :width="!$vuetify.breakpoint.smAndDown ? '200px' : '150px'"
                  />
                </v-col>
                <v-col class="col-1 mr-4" v-if="$vuetify.breakpoint.smAndDown">
                  <v-btn @click="navigationDrawer = false" icon
                    ><v-icon large color="#EFEFEF">mdi-close</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-toolbar-items>
          </v-container>
        </v-app-bar>

        <v-row justify="center" align="center" class="nav-content">
          <v-col class="col-12 text-uppercase text-color-white font-16">
            <v-row class="mb-8" justify="center">
              <div class="cursor-pointer" @click="scrollToId('about_us')">
                Über uns
              </div>
            </v-row>
            <v-row class="mb-8" justify="center">
              <div class="cursor-pointer" @click="scrollToId('projects')">
                Projekte
              </div>
            </v-row>
            <v-row class="mb-8" justify="center">
              <div class="cursor-pointer" @click="scrollToId('production')">
                Produktion
              </div>
            </v-row>
            <v-row class="my-8" justify="center">
              <div class="cursor-pointer" @click="scrollToId('contacts')">
                Kontakte
              </div>
            </v-row>
          </v-col>
        </v-row>

        <MovedArrow @scroll="scrollMobile" :header="true" />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import MovedArrow from "./MovedArrow.vue";

export default {
  components: {
    MovedArrow,
  },
  data: () => ({
    navigationDrawer: false,
  }),
  methods: {
    scrollToId(id) {
      if (this.navigationDrawer) {
        this.navigationDrawer = false;
        document.body.style.height = "auto";
      }
      if (this.$route.name == "main") {
        document.getElementById(id).scrollIntoView({
          behavior: "smooth",
        });
      } else {
        localStorage.moveTo = id;
        this.$router.push({ name: "main" });
      }
    },
    scrollMobile() {
      this.navigationDrawer = false;
      document.body.style.height = "auto";
      let top = window.innerHeight;
      window.scrollTo({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  watch: {
    navigationDrawer: {
      handler(e) {
        if (e) {
          document.body.style.height = "100vh";
        } else {
          document.body.style.height = "auto";
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  position: absolute;
  top: 0px;
  height: 104px !important;
  backdrop-filter: blur(13.5px);
  background-color: rgba(119, 119, 119, 0.2) !important;
  border-bottom: 1px solid rgba(181, 181, 181, 0.1);
  .header-row {
    height: 104px !important;
  }
  &-drawer {
    backdrop-filter: unset !important;
    background-color: transparent !important;
    border-bottom: unset;
  }
  &-mobile {
    height: 88px !important;
    .header-row {
      height: 88px !important;
    }
  }
}
.navigation-drawer {
  height: 100vh;
  position: fixed;
  top: 0px;
  bottom: 0px;
  width: 100vw;
  background: rgba(27, 27, 27, 0.9);
  backdrop-filter: blur(5px);
  overflow: hidden;
  .nav-content {
    height: calc(100vh - 155px) !important;
  }
}

link {
  cursor: pointer !important;
  font-size: 18px;
  position: relative;
  white-space: nowrap;
  color: #efefef;
}

.link::before,
.link::after {
  position: absolute;
  width: 100%;
  height: 2px;
  background: #f39300;
  top: 100%;
  left: 0;
  pointer-events: none;
}

.link::before {
  content: "";
}

.link--metis::before {
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s;
}
// .link--metis:hover {
//   color: #F39300;
// }
// .link--metis {
//   transition: all 0.3s ease-out;
// }
.link--metis:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
}
</style>