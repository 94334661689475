<template>
  <v-row
    class="w-100 justify-center"
    style="height: 10px; position: absolute; bottom: 110px; left: 10px"
    @click="scrollTo"
  >
    <img src="@/assets/images/arrow-down.svg" class="cursor-pointer move-arrow" />
  </v-row>
</template>

<script>
export default {
  props: {
    header: {
      require: false,
      default: false,
    },
  },
  methods: {
    scrollTo() {
      if (this.header && this.$vuetify.breakpoint.smAndDown) {
        this.$emit("scroll");
      } else {
        let top = window.innerHeight;
        if (this.$route.name == "all") {
          top = window.innerHeight - window.innerHeight / 4;
        }
        window.scrollTo({
          top: top,
          left: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes changemargin {
  from {
    margin-top: 0px;
  }

  to {
    margin-top: 5px;
  }
}

.move-arrow {
  animation-duration: 0.86s;
  animation-name: changemargin;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
</style>